import React, { useEffect, useState } from "react";
import { Container, Row, Col } from "react-bootstrap";
import { QuoteFormWrapper, QuoteFormInner, FormSkeleton } from "./style";
import { SkeletonForm, SkeletonText } from "../../../components/skeleton";
const ContactFormQuote = React.lazy(() => import('@components/contactformquote'));

export default function QuoteForm() {
  const [hideSkeleton, setHideSkeleton] = useState(true);
  useEffect(() => {
    const timer = setTimeout(() => {
      setHideSkeleton(false);
    }, 3000);
    return () => clearTimeout(timer);
  }, []);

  return (

    <QuoteFormWrapper>
      <Container>
        <QuoteFormInner>
          <Row>
            <Col>
              {hideSkeleton &&
                <FormSkeleton>
                  <SkeletonForm labelHeight="20px" labelWidth="50%" inputHeight="40px" mb="4px" />
                  <SkeletonForm labelHeight="20px" labelWidth="50%" inputHeight="40px" mb="4px" />
                  <SkeletonForm labelHeight="20px" labelWidth="50%" inputHeight="40px" mb="4px" />
                  <SkeletonForm labelHeight="20px" labelWidth="50%" inputHeight="40px" mb="4px" />
                  <SkeletonForm labelHeight="20px" labelWidth="50%" inputHeight="40px" mb="4px" />
                  <SkeletonForm labelHeight="20px" labelWidth="50%" inputHeight="40px" mb="4px" />
                  <SkeletonForm labelHeight="20px" labelWidth="80px" inputHeight="66px" mb="4px" />
                  <SkeletonForm labelHeight="20px" labelWidth="100px" inputHeight="40px" inputWidth="200px" mb="4px" />
                  <SkeletonText height="18px" width="100%" mb="4px" />
                </FormSkeleton>
              }
              {typeof window !== 'undefined' && (
                <React.Suspense fallback={<div />}>
                  <ContactFormQuote />
                </React.Suspense>
              )}
            </Col>
          </Row>
        </QuoteFormInner>
      </Container>
    </QuoteFormWrapper>

  );
}
